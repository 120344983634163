<script>
import arrowImg from '../../../images/arrow-right-circle.svg'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import getCountryISO2 from "country-iso-3-to-2";

export default {
  components:{
    Loading,
  },
  props: {
    bookingID: Number,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      arrowImg: arrowImg,
      isLoading: false,
      budget: '',
      currency: {},
      currencies: [],
      countries: [],
      isCountriesLoaded: false,
      error: null
    }
  },
  computed: {
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  },
  mounted() {
    this.isLoading = true
    this.$axios.get('/v3/locations')
      .then(response => {
        this.countries = response.data
        this.isCountriesLoaded = true
        this.isLoading = false
      })
    this.$axios.get('/v3/currencies')
      .then(res => {
        this.currencies = res.data
      })
    this.$axios.get(`/v3/event_bookings/${this.bookingID}`)
      .then(response => {
        this.budget = response.data.budget
        this.currency = response.data.currency
      })
      .catch(error => {
        this.isLoading = false
      })
  },
  methods: {
    getCountryFlagCode() {
      const country = this.countries.filter(country => country.slug === localStorage.getItem('location_country'))[0]
      this.currency = country.currency
      const flag = getCountryISO2(country.slug.toUpperCase()).toString().toLowerCase();
      return flag;
    },
    nextStep(isSkip=false) {
      this.isLoading = true
      let params = {
        booking: {
          budget: isSkip ? null : parseInt(this.budget),
          currency_slug: this.currency.slug
        }
      }
      this.$emit('updateStep', { booking_params: params, next_step: 'fill_description' })
    },
    back() {
      let params = {
        booking: {
          budget: parseInt(this.budget),
          currency_slug: this.currency.slug
        }
      }
      this.$emit('updateStep', { booking_params: params, next_step: 'select_guest' })
    },
  },
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>

    <p class="mt-4 mb-5">
      Letting artists know your budget will give you more accurate results
    </p>

    <div class="new-booking__content">
      <div class="forms__item-100">
        <div class="new-booking__input-budget">
          <div v-if="isCountriesLoaded" class="order-capture-input w-100 position-relative">
            <span class="position-absolute h-100">
              <img class="img-flg" :src="'/assets/flags/4x3/' + getCountryFlagCode() + '.svg' "/>
            </span>
            <input
              v-model="budget"
              type="number"
              :required="true"
              class="ps-5 w-100"
              v-bind:class="{ 'error': error }"
              placeholder="Enter maximum budget"
              min=0
            >
          </div>
          <span v-if="error" class="error-message">{{ error? error.toString() : null }}</span>
        </div>

        <button class="w-100 new-booking__skip-budget mb-3"
          @click="nextStep(isSkip=true)"
        >
          Skip
          <img :src="arrowImg" class="ms-1 me-3" style="width: 16px;vertical-align: middle;" />
          Let artists send me quotes
        </button>
      </div>

      <div class="mt-5">
        <v-app class="w-100 mb-4">
          <p class="mb-1">Step {{ activeStep }} of {{ totalSteps }}</p>
          <v-progress-linear
            rounded
            :value="bookingProgress"
            height="8">
          </v-progress-linear>
        </v-app>

        <div class="back-next">
          <button
            class="settings-forms__btn btn secondary fluid"
            @click="back()">
            Back
          </button>
          <button
            class="settings-forms__btn btn primary fluid"
            @click="nextStep()"
            :disabled="!budget || parseInt(budget) <= 0">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
</style>
