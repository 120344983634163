<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components:{
    Loading,
  },
  props: {
    bookingID: Number,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      isLoading: false,
      error: null,
      booking_applications: [],
      password: '',
      fieldType: 'password'
    }
  },
  computed: {
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  },
  mounted() {
    this.isLoading = true
    this.$axios.get(`/v3/event_bookings/${this.bookingID}`)
      .then(response => {
        this.booking_applications = response.data.booking_applications
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
      })
  },
  methods: {
    nextStep() {
      let params = {
        user: {
          password: this.password,
          current_password: this.password,
          role: 'regular'
        }
      }
      this.isLoading = true
      this.$axios.put('/v3/users/profile', params)
        .then(response => {
          let login_params = {
            user: {
              email: response.data.email,
              password: this.password
            }
          }
          this.$axios.post('/users/sign_in.json', login_params).then(res => {
            if (this.booking_applications.length <= 0) {
              this.$emit('updateStep', { next_step: 'booking_complete' })
            } else {
              this.$emit('updateStep', { next_step: 'select_booking_type' })
            }
          })
        })
        .catch(error => {
          this.isLoading = false
        })
    },
    back() {
      this.$emit('updateStep', { next_step: 'fill_phone' })
    },
  },
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>

    <div class="complete-signup-header__title">
      Create a
      <span class='complete-signup-header__primary_title'>login password</span>
    </div>

    <p class="mt-4 mb-4">
      Provide a password for your account
    </p>

    <div class="new-booking__content">
      <div class="forms__item-100">
        <div class="forms__item-wp">
          <input
            v-model="password"
            :type="fieldType"
            :required="true"
            class="input order-capture-input border-0"
            v-bind:class="{ 'error': error}"
            placeholder="********"
            autocomplete="chrome-off">
          <svg
            class="password-watch-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            :fill="fieldType=='password'?'#DADFEA':'#FF8141'"
            viewBox="0 0 24 24"
            v-on:click="()=>{fieldType = fieldType=='text'?'password':'text'}">
            <g fill-rule="evenodd">
              <path  d="M16.6902054,7.25771868 C18.6867614,8.09104409 21.1233596,9.3384712 24,11 L24,13 C20,17 16,19 12,19 C8,19 4,17 8.8817842e-16,13 L8.8817842e-16,11 C2.87615842,9.34200151 5.31134218,8.09634687 7.30555128,7.26303607 C6.48838509,8.2882196 6,9.58707557 6,11 C6,14.3137085 8.6862915,17 12,17 C15.3137085,17 18,14.3137085 18,11 C18,9.58463161 17.5099239,8.28372662 16.6902054,7.25771868 Z"/>
              <path  d="M13.1778781,7.1762437 C12.4833529,7.48981554 12,8.18846121 12,9 C12,10.1045695 12.8954305,11 14,11 C14.8115388,11 15.5101845,10.5166471 15.8237563,9.8221219 C15.9383283,10.194496 16,10.5900459 16,11 C16,13.209139 14.209139,15 12,15 C9.790861,15 8,13.209139 8,11 C8,8.790861 9.790861,7 12,7 C12.4099541,7 12.805504,7.06167174 13.1778781,7.1762437 Z"/>
            </g>
          </svg>

          <span v-if="error" class="error-message">{{ error? error.toString() : null }}</span>
        </div>
      </div>

      <div class="mt-5">
        <v-app class="w-100 mb-4">
          <p class="mb-1">Step {{ activeStep }} of {{ totalSteps }}</p>
          <v-progress-linear
            rounded
            :value="bookingProgress"
            height="8">
          </v-progress-linear>
        </v-app>

        <div class="back-next">
          <button
            class="settings-forms__btn btn secondary fluid"
            @click="back()">
            Back
          </button>
          <button
            class="settings-forms__btn btn primary fluid"
            :disabled="!password || password === ''"
            @click="nextStep()">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.forms__item-wp {
  position: relative;

  .password-watch-icon {
    top: 10px;
  }
}
</style>
