<script>
import moment from 'moment'
import * as _ from 'lodash'

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components:{
    Loading,
    flatPickr
  },
  props: {
    bookingID: Number,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      moment: moment,
      isShowSelectGenreScreen: false,
      isLoading: false,
      dates: [new Date()],
      currentDates: [],
      busy_days: [],
      dateConfig: {
        static: true,
        inline: true,
        mode: 'multiple',
        enable: [
          function(date) {
            return date >= new Date().setHours(0,0,0,0);
          }
        ]
      }
    }
  },
  watch: {
    busy_days(newState, oldState) {
      console.log(newState)
      this.dateConfig.enable = [
        function(date) {
          if (newState.length == 0) { return date >= new Date().setHours(0, 0, 0, 0) }
          return newState.every( days => date.toLocaleDateString('fr-CA') != days.date && date >= new Date().setHours(0, 0, 0, 0) );
        }
      ]
    }
  },
  mounted() {
    this.$axios.get(`/v3/event_bookings/${this.bookingID}`)
      .then(response => {
        this.isShowSelectGenreScreen = !(_.find(response.data.booking_art_types, (art_type) => art_type.slug === 'musicians' || art_type.slug === 'djs') == undefined)
        this.currentDates = response.data.booking_dates
        this.dates = response.data.booking_dates.map(date => date.date).join(', ')
        if (response.data.booking_applications.length > 0) {
          this.$axios.get(`/v3/users/busy_days`, {
            params: {
              user_id: response.data.booking_applications[0].artist_id
            }
          }).then((response) => {
            this.busy_days = response.data
          })
        }
      })
  },
  computed: {
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  },
  methods: {
    dateDeselected(date) {
      this.dates = this.dates.split(',').filter(selected_date => selected_date !== date).join(', ')
    },
    bookingParams() {
      let new_dates = this.dates.split(',').map(date => {
        let currentIndex = this.currentDates.findIndex(current_date => new Date(current_date.date).toDateString() === new Date(date).toDateString())
        return {
          id: currentIndex >=0 ? this.currentDates[currentIndex].id : null,
          date: date,
          start_time: new Date(new Date(date).setHours(7)),
          end_time: new Date(new Date(date).setHours(19)),
        }
      })

      let removed_dates = this.currentDates.map(date => {
        let index = this.dates.split(',').findIndex(new_date => new Date(new_date).toDateString() === new Date(date.date).toDateString())
        if (index < 0) {
          return {
            id: date.id,
            date: date.date,
            _destroy: true
          }
        }
      }).filter(item => item)

      return {
        booking: {
          booking_dates_attributes: new_dates.concat(removed_dates)
        }
      }
    },
    nextStep() {
      this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_location' })
    },
    back() {
      const params = this.bookingParams()
      if (this.isShowSelectGenreScreen) {
        this.$emit('updateStep', { booking_params: params, next_step: 'select_genre' })
      } else {
        this.$emit('updateStep', { booking_params: params, next_step: 'select_speciality' })
      }
    },
  },
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>

    <p class="mt-4 mb-5">
      Let artist(s) know when your event is happening so that only those available will apply
    </p>

    <div v-if="dates.length > 0" class="mb-2">
      <h6 class="mb-3">Selected Event Dates</h6>
      <ul class="selected-dates-container">
        <li
          class="selected-date-item"
          v-for="(date, index) in dates.split(',')"
          :key="index"
          @click="dateDeselected(date)">
          {{ moment(date).format('DD/MM/YYYY') }}  {{ `(${moment(date).format('dddd')})` }}
          <span class="ms-auto">
            <font-awesome-icon :icon="['fas', 'circle-xmark']" style="color: #ff6161;" />
          </span>
        </li>
      </ul>
    </div>

    <div class="new-booking__content">
      <p class="mb-1">Select event date(s)</p>
      <div class="mt-3">
        <flat-pickr
          v-model="dates"
          :config="dateConfig"
          class="input order-capture-input border-0"
          placeholder="Event Dates"
          name="date"/>
      </div>

      <div class="mt-5">
        <v-app class="w-100 mb-4">
          <p class="mb-1">Step {{ activeStep }} of {{ totalSteps }}</p>
          <v-progress-linear
            rounded
            :value="bookingProgress"
            height="8">
          </v-progress-linear>
        </v-app>

        <div class="back-next">
          <button
            class="settings-forms__btn btn secondary fluid"
            @click="back()">
            Back
          </button>
          <button
            class="settings-forms__btn btn primary fluid"
            @click="nextStep()"
            :disabled="dates.length <= 0">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
</style>
