<script>

import Multiselect from 'vue-multiselect'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components:{
    Loading,
    Multiselect
  },
  props: {
    bookingID: Number,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      isLoading: false,
      art_types: [],
      start_time:'',
      artist_busy_days:[],
      busy_day:'',
      art_type: {},
      current_index: 0,
      durations: ["0:15", "0:30", "0:45"],
      error: null
    }
  },
  computed: {
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  },
  mounted() {
    for (let i = 1; i <= 11 ; i++) {
      this.durations.push(`${i}:00`)
      this.durations.push(`${i}:30`)
    }
    this.isLoading = true
    this.$axios.get(`/v3/event_bookings/${this.bookingID}`)
      .then(response => {
        this.art_types = response.data.booking_art_types
        this.art_type = response.data.booking_art_types[0]
        this.start_time = response.data.start_time
        this.getArtistBusyDays(response.data.booking_applications[0].artist_id);
        this.current_index = 0
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
      })
  },
  methods: {
    getArtistBusyDays(user_id) {
      this.$axios.get(`/v3/users/busy_days`, {
          params: {
            user_id: user_id
          }
        }).then((response) => {
          this.artist_busy_days = response.data
        })
    },
    bookingParams() {
      return {
        booking: {
          booking_art_types_attributes: this.art_types.map(art_type => {
            return {
              id: art_type.id,
              duration: art_type.duration
            }
          })
        }
      }
    },
    nextStep() {
      const [duration_hours, duration_mins] = this.art_types[0].duration.split(":");
      let starting_time = new Date(this.start_time.substring(0, this.start_time.length-1));
      starting_time.setHours(starting_time.getHours() + parseInt(duration_hours), starting_time.getMinutes() + parseInt(duration_mins));
      if (this.busy_day = this.artist_busy_days.find((item) => item.date === starting_time.toLocaleDateString('fr-CA'))){
        let error = document.querySelector(".error-message")
        this.isLoading = false;
        error.style.display = "block";
        return;
      }
      this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_event_type' })
    },
    nextArtType() {
      let old_index = this.current_index
      this.current_index = old_index + 1
      this.art_type = this.art_types[old_index + 1]
    },
    previousArtType() {
      let old_index = this.current_index
      this.current_index = old_index - 1
      this.art_type = this.art_types[old_index - 1]
    },
    back() {
      this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_time' })
    },
    customLabel(title) {
      let time = title.split(':')
      let hour = time[0] === '0' ? '' : (time[0] === '1' ? '1 hour' : `${time[0]} hours`)
      let min = time[1] === '00' ? '' : ` ${time[1]} minutes`
      return hour + min
    }
  },
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>

    <div class="complete-signup-header__title">
      How long will the <span class="cr-primary">{{ art_type.name }}(s)</span> be needed for?
    </div>

    <p class="mt-4 mb-5">
      Let the artist(s) know how long they will have to stay at the event from the moment they arrive
    </p>

    <div class="new-booking__content">
      <p class="mb-3">Select performance duration</p>
      <div class="mb-3">
        <ul class="durations-container">
          <li
            class="duration-item ms-2"
            v-for="(duration, index) in durations"
            :key="index"
            @click="art_type.duration = duration">
            {{ customLabel(duration) }}
            <span class="ms-auto">
              <font-awesome-icon v-if="art_type.duration === duration" :icon="['fas', 'circle-check']" style="color: #ff8141;" />
              <font-awesome-icon v-else :icon="['fas', 'circle']" style="color: #dcdcdc;" />
            </span>
          </li>
        </ul>
      </div>

      <div class="mt-5">
        <v-app class="w-100 mb-4">
          <p class="mb-1">Step {{ activeStep }} of {{ totalSteps }}</p>
          <v-progress-linear
            rounded
            :value="bookingProgress"
            height="8">
          </v-progress-linear>
        </v-app>

        <div class="back-next">
          <button
            v-if="current_index > 0"
            class="settings-forms__btn btn secondary fluid"
            @click="previousArtType()">
            Back
          </button>
          <button
            v-if="current_index === 0"
            class="settings-forms__btn btn secondary fluid"
            @click="back()">
            Back
          </button>
          <button
            v-if="current_index < art_types.length - 1"
            class="settings-forms__btn btn primary fluid"
            :disabled="!art_type.duration"
            @click="nextArtType()">
            Next
          </button>
          <button
            v-if="current_index === art_types.length - 1"
            class="settings-forms__btn btn primary fluid"
            @click="nextStep()"
            :disabled="!art_type.duration">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
