<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import messageIcon from '../../../images/message_orange-xl.svg'

export default {
  components:{
    Loading,
  },
  props: {
    bookingID: Number
  },
  data(){
    return {
      messageIcon: messageIcon,
      isLoading: false,
      error: null,
      stage_name: ''
    }
  },
  watch: {
  },
  mounted() {
    this.isLoading = true
    this.$axios.get(`/v3/event_bookings/${this.bookingID}`)
      .then(response => {
        this.stage_name = response.data.booking_applications[0].stage_name
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
      })
    localStorage.setItem('isMessageSelected', true)
  },
  methods: {
    nextStep() {
      this.$emit('updateStep', { next_step: 'select_date' })
    }
  },
  computed: {
  },
}
</script>

<template>
  <div>
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>
    <div class="text-center">
      <img :src="messageIcon" class="mb-3" />
      <div class="complete-signup-header__title">
        Sending a message to
        <span class='complete-signup-header__primary_title'>
          {{ stage_name }}
        </span>
      </div>
    </div>
    <p class="text-center w-100 mb-3 mt-3">
      Direct messages to this artist can be sent once you fill in your event details.
    </p>

    <p class="text-center w-100 mb-3 mt-3">
      After the artist quotes you and confirms their availability then you will be able to speak with them directly.
    </p>

    <div class="new-booking__content mt-5">
      <div class="back-next">
        <button
          class="settings-forms__btn btn primary fluid"
          @click="nextStep()"
        >Next</button>
      </div>
    </div>
  </div>
</template>
<style scoped>
</style>
