<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components:{
    Loading,
  },
  props: {
    bookingID: Number,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      isLoading: false,
      booking_art_types: [],
      guests: '',
      sound_equipment: true,
      error: null
    }
  },
  computed: {
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  },
  mounted() {
    this.isLoading = true
    this.$axios.get(`/v3/event_bookings/${this.bookingID}`)
      .then(response => {
        this.booking_art_types = response.data.booking_art_types
        this.guests = response.data.guests
        this.sound_equipment = response.data.sound_equipment
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
      })
    this.$refs.guests.focus()
  },
  methods: {
    bookingParams() {
      return {
        booking: {
          guests: this.guests,
          sound_equipment: this.sound_equipment
        }
      }
    },
    nextStep() {
      this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_budget' })
    },
    back() {
      this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_event_type' })
    },
  },
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>

    <p class="mt-4 mb-5">
      Let the artist(s) know how many guests will be at your event so they may tailor their performaces accordingly.
    </p>

    <div class="new-booking__content">
      <p class="mb-3">Select number of guests</p>
      <div class="forms__item-100">
        <div class="forms__item-wp">
          <input
            v-model="guests"
            type="number"
            :required="true"
            class="input order-capture-input border-0"
            v-bind:class="{ 'error': error}"
            placeholder="Enter number of guests"
            ref="guests"
            min=0>
          <span v-if="error" class="error-message">{{ error? error.toString() : null }}</span>

          <div v-if="booking_art_types.filter(art_type => art_type.slug === 'musicians' || art_type.slug === 'djs').length > 0">
            <div class="complete-signup-header__title mt-5">
              Do you require <span class='complete-signup-header__primary_title'>sound equipment?</span>
            </div>
            <p class="mb-4">Do you want the artists to include sound equipment in their quote</p>

            <ul class="sound-equipment-options-container">
              <li
                  :class="{ 'sound-equipment-selected-item': sound_equipment, 'sound-equipment-item': !sound_equipment }"
                  @click="sound_equipment = true">
                Yes
                <span class="ms-auto">
                <font-awesome-icon v-if="sound_equipment" :icon="['fas', 'circle-check']" style="color: #ff8141;" />
                <font-awesome-icon v-else :icon="['fas', 'circle']" style="color: #dcdcdc;" />
              </span>
              </li>
              <li
                  :class="{ 'sound-equipment-selected-item': !sound_equipment, 'sound-equipment-item': sound_equipment }"
                  @click="sound_equipment = false">
                No
                <span class="ms-auto">
                <font-awesome-icon v-if="!sound_equipment" :icon="['fas', 'circle-check']" style="color: #ff8141;" />
                <font-awesome-icon v-else :icon="['fas', 'circle']" style="color: #dcdcdc;" />
              </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <v-app class="w-100 mb-4">
          <p class="mb-1">Step {{ activeStep }} of {{ totalSteps }}</p>
          <v-progress-linear
            rounded
            :value="bookingProgress"
            height="8">
          </v-progress-linear>
        </v-app>

        <div class="back-next">
          <button
            class="settings-forms__btn btn secondary fluid"
            @click="back()">
            Back
          </button>
          <button
              class="settings-forms__btn btn primary fluid"
              @click="nextStep()"
              :disabled="!guests || parseInt(guests) <= 0">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
</style>
