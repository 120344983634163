<script>
  import RadialProgressBar from 'vue-radial-progress'
  import logoImg from '../../../images/logo.svg'

  export default {
    props: {
      step: String,
    },
    components: {
      RadialProgressBar
    },
    data(){
      return {
        logoImg: logoImg,
        isMessageSelected: JSON.parse(localStorage.getItem('isMessageSelected', 'false'))
      }
    },
    computed: {
      completedSteps(){
        if (['fill_name', 'fill_email', 'fill_phone', 'fill_password'].includes(this.step)){
          return 9
        }
        const steps = [
          'select_artist_type', 'select_speciality', 'select_genre', 'select_date', 'select_location',
          'select_time', 'select_duration', 'select_event_type', 'select_guest',
          'select_budget', 'fill_description', 'select_booking_type', 'booking_complete'
        ]
        return steps.findIndex(_step => _step == this.step)
      },
      titleDisplay() {
        const step = this.step
        switch(step) {
          case "select_artist_type":
            return "What <span class='complete-signup-header__primary_title'>type of artist(s)</span> are you looking for?"
          case "select_speciality":
            return ''
          case "select_date":
            return "<span class='complete-signup-header__primary_title'>When is your event</span> taking place?"
          case "select_location":
            return "<span class='complete-signup-header__primary_title'>Where is your event</span> taking place?"
          case "select_time":
            return ""
          case "select_duration":
            return ""
          case "select_event_type":
            return "What <span class='complete-signup-header__primary_title'>type of event </span> will this be?"
          case "select_guest":
            return "<span class='complete-signup-header__primary_title'>How many guests</span> are you expecting?"
          case "select_budget":
            return "What is your <span class='complete-signup-header__primary_title'>maximum budget?</span>"
          case "fill_description":
            return ""
          case "select_booking_type":
            if (this.isMessageSelected) {
              return "<span class='complete-signup-header__primary_title'>Your request has been sent</span>"
            } else {
              return "Would you like to <span class='complete-signup-header__primary_title'>receive quotes from similar artists?</span>"
            }
          case "booking_complete":
            return ""
          default:
            return ''
        }
      }
    }
  }
</script>

<template>
  <section>
    <slot name='header'>
      <div class="complete-signup-header mb-2" v-if="step != 'done' && step != 'message_info'">
<!--        <img :src="logoImg" class="all-signed-up-option-header__logo" v-if="step === 'booking_complete'" />-->
<!--        <radial-progress-bar-->
<!--          v-else-->
<!--          :diameter="84"-->
<!--          :strokeWidth="7"-->
<!--          :innerStrokeWidth="7"-->
<!--          innerStrokeColor="#FFDCCB"-->
<!--          startColor="#FF8141"-->
<!--          stopColor="#FF8141"-->
<!--          :animateSpeed="500"-->
<!--          :completed-steps="completedSteps"-->
<!--          :total-steps="11" class="complete-signup-header__logo">-->
<!--          <p>{{ parseInt(completedSteps * 100 / 11) }}%</p>-->
<!--        </radial-progress-bar>-->
        <div class="complete-signup-header__title" v-html="titleDisplay"></div>
      </div>
    </slot>

    <slot></slot>
  </section>
</template>
